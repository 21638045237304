<template>
  <ValidationObserver ref="observe">
    <div>
      <!--details message & response to message-->
      <b-card>
        <template #header>
          <div>
            {{ contactDto.senderName }}
          </div>
          <div>
            {{ contactDto.phoneNumber }}
          </div>
          <div>
            {{ formDate(contactDto.contactDate) }}
            <b-button
              variant="transparnt"
              class="p-0"
              @click="deleteContact(contactDto.id)"
            >
              <unicon fill="gray" height="20px" name="trash-alt"> </unicon>
            </b-button>
          </div>
        </template>
        <b-card-text>
          <h4 class="card-title">
            {{ contactDto.title }}
          </h4>
          <p>
            {{ contactDto.content }}
          </p>
        </b-card-text>
      </b-card>
      <b-card class="text d-flex p-0">
        <template #header>
          <p class="reply">الرد على الرسالة</p>
          <h6 class="reply">
            {{ timeNow }}
          </h6>
        </template>
        <ek-input-textarea
          :readonly="contactDto.isResponsed ? true : false"
          name="reply"
          placeholder="يرجى الرد على الرسالة"
          v-model="contactDto.response"
        ></ek-input-textarea>
        <template #footer>
          <div class="w-100 d-flex justify-content-end">
            <div>
              <b-button variant="primary" @click="submit"> إرسال </b-button>
              <b-button variant="outline-primary " class="mx-1" to="/admin/contactUs"
                >تراجع</b-button
              >
            </div>
          </div>
        </template>
      </b-card>
    </div>
  </ValidationObserver>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { nullGuid } from "@Ekcore/util/global.js";

export default {
  props: {
    id: String,
  },
  data: () => ({
    nullGuid,
    timeNow: "",
  }),
  computed: {
    ...mapState({
      contactDto: (state) => state.contactUs.contactDto,
    }),
  },
  methods: {
    ...mapActions(["getContactDetails", "updateContact", "deleteContact"]),
    formDate(date) {
      let dete = `${new Date(date).toLocaleDateString()}`;
      return dete;
    },
    getNow() {
      const today = new Date();
      const date =
        today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
      this.timeNow = date;
    },

    //submit to response message
    submit() {
      this.$refs["observe"].validate().then((suc) => {
        if (suc) {
          this.updateContact({
            contactUsId: this.contactDto.id,
            response: this.contactDto.response,
          });
        }
      });
    },
  },
  created() {
    this.getContactDetails(this.id);
  },
  mounted() {
    this.getNow();
  },
};
</script>

<style scoped>
.text {
  justify-content: space-between;
  background-color: white;
  padding: 10px;
}
.reply {
  color: gray;
  font-size: 13px;
}
</style>
